import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from "react-timezone-select";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import { EXCHANGE_URLS_BUYER } from "../../Important/URLS";
import { FormLabel } from "react-bootstrap";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Helmet } from "react-helmet";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { QuestionSection } from "./QuestionSection";
import { setBookingData } from "../../../redux/users/action";
import { useDispatch } from 'react-redux'; // Importing useDispatch



const validationSchema = Yup.object({
  time_zone: Yup.string().required("Time Zone is Required"),
  date_time: Yup.date()
    .required("Date & Time is Required")
    .min(new Date(), "Date must be in the future"),
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .required("Email is Required")
    .email("Invalid email format"),
  services: Yup.array().min(1, "At least one service is required"),
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Phone Number must be 10 digits")
    .required("Phone Number is required"),
  comment: Yup.string().optional(),
});

const names = [
  "Application Development",
  "Custom Website Development",
  "Ecommerce Website Development",
  "Shopify Development",
  "shopify custom app development ",
  "shopify public app development ",
  "Shopify API Development",
  "Web Design",
  "Website Development",
  "woocommerce developement",
  "WordPress Plugin",
];

export const Apply = () => {
  
  const theme = useTheme();
  
  const [errors, setErrors] = useState({});
  const [personName, setPersonName] = React.useState([]);
  const [startDate, setStartDate] = useState(new Date());



  const [inputData, setInputData] = useState({
    time_zone: "",
    date_time: "",
    name: "",
    mobile_number: "",
    email: "",
    services: [],
    comment: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false); 

  const [selectedTimezone, setSelectedTimezone] = useState(" ");


  useEffect(() => {
    const detectTimezone = async () => {
      try {
        // 1. Detect timezone using Intl API
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setSelectedTimezone(browserTimezone);

        setInputData((prevData) => ({ ...prevData, time_zone: browserTimezone }));

        // 2. Optional: Use ipapi service for more accurate timezone detection
        const response = await axios.get('https://ipapi.co/json/');
        if (response.data && response.data.timezone) {
          setSelectedTimezone(response.data.timezone);
          setInputData((prevData) => ({ ...prevData, time_zone: response.data.timezone }));
        }
      } catch (error) {
        console.error("Error detecting timezone:", error);
      }
    };

    detectTimezone();
  }, []);
  const dispatch = useDispatch();


  const formatDate = (date) => {
    const formattedDate = new Date(date);
  
    let hours = formattedDate.getHours();
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const day = String(formattedDate.getDate()).padStart(2, '0');
    const year = formattedDate.getFullYear();
  
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    return `${year}-${month}-${day} & ${hours}:${minutes} ${ampm}`;
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setInputData((prevData) => ({
      ...prevData,
      date_time: formatDate(date), 
    }));
  };


  const handleServiceChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setPersonName((prev) => [...prev, value]);
      setInputData((prevData) => ({
        ...prevData,
        services: [...prevData.services, value],
      }));
    } else {
      setPersonName((prev) => prev.filter((name) => name !== value));
      setInputData((prevData) => ({
        ...prevData,
        services: prevData.services.filter((service) => service !== value),
      }));
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedInputData = {
        ...inputData,
       date_time: formatDate(inputData.date_time)
      };

      console.log("Formatted Input Data", formattedInputData);

      await validationSchema.validate(inputData, { abortEarly: false });
      console.log("Form Submitted", inputData);
      const response = await axios.post(
        `${EXCHANGE_URLS_BUYER}/booking`,
        inputData
      );

      if (response.status === 200) {
        toast.success("Thank You for Submitting");
        setIsSubmitted(true);
        console.log("Book Id",response.data.data.id)
        const bookid = response.data.data.id;
        dispatch(setBookingData(bookid));
      }

    } catch (validationErrors) {
      const errors = validationErrors?.inner?.reduce((acc, currentError) => {
        acc[currentError.path] = currentError.message;
        return acc;
      }, {});
      setErrors(errors);
      console.log(errors);
    }
  };

 

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone?.value);
    setInputData((prevData) => ({ ...prevData, time_zone: timezone?.value }));
  };

  const getStyles = (name, personName, theme) => {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Root>

      <Helmet>
        <title>Book Your Appointment</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10900846860"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10900846860');
          `}
        </script>
      </Helmet>

      <div id="form">
        <h2 className="first-content" >
          Book
          <span style={{ color: "#0b66c2" }}> an </span> Appointment
        </h2>

        <form onSubmit={handleFormSubmit}>


          <div className="cal_div">
            <div className="select-wrapper">

              <TimezoneSelect
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                name="time_zone"
                placeholder="Select Timezone"
                isClearable={true}
              />
              {errors?.time_zone && <Error>*{errors.time_zone}</Error>}
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}  >
                  <DateTimePicker
                   selected={startDate}
                   onChange={handleDateChange}
                   name="date_time"
                   label="Select Date & Time" 
                   className="date_custom"
                   />
                </DemoContainer>
              </LocalizationProvider>



              {errors?.date_time && <Error>*{errors.date_time}</Error>}



              {/* <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="Pp"
                className="cale_time"
                name="date_time"
              /> */}
      
            </div>
          </div>

          <div className="form_div">
            <div className="container-fluid">
              <div className="row  fiel_div">
                <div className="col-lg-6 col-md-12 col-12">
                  <input
                    type="text"
                    placeholder="Name*"
                    name="name"
                    value={inputData.name}
                    onChange={handleInputChange}
                    required
                  />
                  {errors?.name && <Error>*{errors.name}</Error>}
                </div>

                <div className="col-lg-6 col-md-12 col-12">
                  <input
                    type="tel"
                    placeholder="Phone*"
                    name="mobile_number"
                    value={inputData.mobile_number}
                    onChange={handleInputChange}
                    required
                  />
                  {errors?.mobile_number && (
                    <Error>*{errors.mobile_number}</Error>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 col-12">
                      <input
                        type="email"
                        placeholder="Email Address*"
                        name="email"
                        value={inputData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  {errors?.email && <Error>*{errors.email}</Error>}

                  <div className="row mt-3 show-hide">
                    <div className="col-lg-12 col-md-12 col-12">
                      <label className="label_heading">
                        Share Project Details(Optional)
                      </label>
                      <textarea
                        name="comment"
                        rows="5"
                        cols="75"
                        value={inputData.comment}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <FormLabel className="label_heading Service_div">Services</FormLabel>
                  <FormGroup>
                    {names.map((name) => (
                      <FormControlLabel
                        key={name}
                        control={
                          <Checkbox
                            value={name}
                            checked={personName.includes(name)}
                            onChange={handleServiceChange}
                          />
                        }
                        label={name}
                      />
                    ))}
                  </FormGroup>
                  {errors?.services && <Error>*{errors.services}</Error>}
                </div>
              </div>
              <div className="row mt-3 hide-show">
                <div className="col-lg-12 col-md-12 col-12">
                  <label className="label_heading">
                    Share Project Details(Optional)
                  </label>
                  <textarea
                    name="comment"
                    rows="5"
                    cols="75"
                    value={inputData.comment}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="sub_btn">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </form>

         {isSubmitted && <QuestionSection />}




      </div>



    </Root>
  );
};

const Root = styled.section`
  padding: 2% 10%;
  .hide-show {
    display: none;
  }

  .css-hlgwow {
    padding: 5px 8px;
  }


  .MuiFormGroup-root {
    height: 253px;
    @media (max-width: 999px) {
      height: auto;
    }
  }

  .css-v0pjtz-MuiStack-root {
  padding-top:0px;
   background-color: #f3f3f3;
}

.css-v0pjtz-MuiStack-root {
   overflow: unset; 
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 14px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500!important;
    font-size: 1rem!important;
    line-height: 1.5!important;
    letter-spacing: 0.00938em!important;
    color:#334770!important;
    top:-4px;
}


  .css-1dimb5e-singleValue {
    color: #334770;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #334770;
  }

  .first-content {
    font-size: 45px;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom: 20px;
    word-spacing: 5px;
    text-align: center;
  }

  .time_heading {
    display: flex;
    justify-content: center;
    span {
      font-size: 19px;
      font-weight: 600;
      line-height: 1.3em;
    }
  }

  .cal_div {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;

    .select-wrapper{
    width:380px;
    }

    .date_custom{
    ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root {
    width: 90px!important;
}
    }

    input.cale_time {
      padding: 10px;
      border-radius: 5px;
      background-color: #f3f3f3;
      border: 1px solid #dadada;
      color: #334770;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #6b7996;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 0.3rem;
      background-color: #13c9df;
      color: #fff;
    }

    .css-1fdsijx-ValueContainer {
      padding: 5px 8px;
    }
    .css-13cymwt-control {
      margin-top: 1px;
      /* width: 225px; */
      background-color: #f3f3f3;
    }

    .css-qbdosj-Input {
      color: #6b7996 !important;
    }

    .mon_head {
      font-size: 20px;
      font-weight: 500;
    }
    .main {
      width: 20rem;
      padding: 0.7rem;
      margin-left: 1rem;
      border: 1px solid #dadada;
    }
    .header {
      display: flex;
      margin-bottom: 0.7rem;
      justify-content: space-between;
      align-items: center;
    }
    .header button {
      font-size: 1.4rem;
      cursor: pointer;
      color: #13c9df !important;
      border: 1px solid transparent !important;
    }
    .body {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
    }
    .box {
      height: 2rem;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .box.empty {
      background-color: #e0e0e0;
      cursor: default;
    }
    .box.selected {
      background-color: #13c9df;
      color: white;
    }
  }

  .form_div {
    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="tel"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    input[type="email"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    textarea {
      width: 100%;
      background: #f3f3f3;
      padding: 7px 15px;
      border: 1px solid #dadada;
    }

    .sub_btn {
      display: flex;
      justify-content: center;
      button {
        font-size: 14px;
        color: white;
        text-transform: uppercase;
        padding: 13px 81px;
        border: 0;
        letter-spacing: 3px;
        border-radius: 50px;
        background-image: linear-gradient(101deg, #012d64, #06b0ff);
        box-shadow: 0 10px 20px #0586ceb3;
      }
    }

    .serv_div {
      width: 100%;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      background: #f3f3f3 !important;
    }
  }





  // -----





  @media (max-width: 567px) {
    padding: 8% 5%;

      .Service_div {
    margin-top: 20px;
}
    



    .container-fluid {
      padding: 0px;
    }
    .show-hide {
      display: none;
    }
    .hide-show {
      display: block;
    }
    .first-content {
      font-size: 35px;
      text-align: center;
    }

    .fiel_div {
      display: flex;
      gap: 20px;
    }

    .cal_div {
      flex-direction: column;
    }

  .cal_div .select-wrapper {
    width: unset;
}

    .cal_div input.cale_time {
      width: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .Service_div {
    margin-top: 20px;
}
    
    .fiel_div {
      display: flex;
      gap: 20px;
    }
    .show-hide {
      display: none;
    }
    .hide-show {
      display: block;
    }
    .container-fluid {
      padding: 0px;
    }
  }
`;
const Error = styled.p`
  font-size: 12px;
  color: red;
  font-weight: 500;
  margin: 2px 0px 0px;
`;
