import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { secGradient } from "../../../GlobalStyles";
import axios from "axios";
import {
  EXCHANGE_URLS_BUYER,
  EXCHANGE_URLS_IMAGES,
} from "../../Important/URLS";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Section11() {
  const navigate = useNavigate();
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    const getSliderApi = async () => {
      try {
        const res = await axios.get(
          `${EXCHANGE_URLS_BUYER}/blogsget?category=SEO (Search Engine Optimisation)`
        );
        if (res?.status === 200) {
          setBlogsData(res?.data?.data || []);
          console.log("blogdata", res?.data?.data);
        }
      } catch (err) {
        toast.error(err, "Error");
      }
    };

    getSliderApi();
  }, [blogsData]);

  // const handleBlogClick = (id) => {
  //   navigate(`/blog-details/${id}`);
  // };

  const generateSlug = (slug) => {
    if (!slug || typeof slug !== "string") return ""; // Safeguard for null or non-string values
    return slug
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim extra spaces
  };

  const handleBlogClick = (id, slug = "", event = null) => {
    const cleanSlug = generateSlug(slug);
    const url = `/blog-details/${id}/${cleanSlug}`;
    if (event?.ctrlKey || event?.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Root>
      <div className="container-fluid">
        <div className="sub_div">
          <h1>
            <span>Reading Our</span> Blog Content
          </h1>
        </div>
        <div className="blog_div">
          {blogsData.slice(0, 3).map((blog, index) => (
            <div
              className="sub_blog"
              key={index}
              onClick={() => handleBlogClick(blog?.id)}
            >
              <a
                href={`/blog-details/${blog.id}/${generateSlug(blog.slug)}`} // Use generateSlug here
                onClick={(e) => {
                  e.stopPropagation(); //Prevent triggering parent onClick
                  handleBlogClick(e, blog.id, blog.slug);
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={`${EXCHANGE_URLS_IMAGES}/${blog?.image}`}
                  alt={`Blog: ${blog?.title || "Image"}`}
                  loading="lazy"
                />
              </a>

              <h4>{blog?.title}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    blog?.description?.length > 150
                      ? blog.description.substring(0, 150) + "..."
                      : blog.description || ""
                  ),
                }}
              />
              <div className="actions">
                <a
                  href={`/blog-details/${blog.id}/${generateSlug(blog.slug)}`} // Use generateSlug here
                  onClick={(e) => {
                    e.stopPropagation(); //Prevent triggering parent onClick
                    handleBlogClick(e, blog.id, blog.slug);
                  }}
                  className="read_more"
                  style={{ cursor: "pointer" }}
                >
                  <FaArrowRightLong /> Read More
                </a>
              </div>
            </div>
          ))}
        </div>
        <div style={{ textAlign: "center" }} className="view_div">
          <a href="/blogs">View All </a>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  margin: auto;
  background-color: #ffffffd4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    color: white;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 50px;
    background-color: #13c9df;
    width: 150px;
    text-align: center;
    padding: 10px 10px;
    margin: 10px;
    margin-top: 20px;
    &:hover {
      background-color: #13c9df;
    }
  }

  .sub_div {
    margin: 30px 0;
    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      span {
        background: ${secGradient};
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .blog_div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    .sub_blog {
      width: 28vw;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 10px;
      border: 1px solid #e1e5ee;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 3 / 2;
      }

      h4 {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        color: #212529;
        text-align: left;
        padding: 0 20px;
      }
      p {
        padding: 0 20px;
        font-size: 16px;
        font-weight: 400;
        color: #6e6e6e;
        margin: 0;
        text-align: left;
      }
      .read_more {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        padding: 0 20px;
        margin-bottom: 20px;
        color: #13c9df;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }

  .view_div {
    margin: 30px 0;
    a {
      color: white;
      cursor: pointer;
      font-size: 16px;
      border: 1px solid transparent;
      border-radius: 50px;
      background-color: #13c9df;
      width: 150px;
      text-align: center;
      padding: 10px 25px;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }
  @media (max-width: 876px) {
    padding: 10px 20px;
    img {
      width: 80vw;
    }
    .blog_div .sub_blog {
      width: 100%;
    }
  }
`;
